import HomeTitle from '../HomeTitle';
import style from './index.module.scss';
// import animationData from '../../../../asset/map.json';
import map from '../../../../images/homeMap.png';
import mapEn from '../../../../images/homeMapEn.png';
import logo1 from '../../../../images/about_logo1.png';
import logo2 from '../../../../images/about_logo2.png';
// import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const defaultOptions = {
    loop: false,
    autoplay: true,
    // animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};
const About = () => {
    const { t, i18n } = useTranslation();
    const isEN = i18n.language === 'en';
    const is1024 = useSelector(state => state.windowSizeReducer.mobileType === 1024);

    return (
        <div
            className={`${style.container} ${i18n.language === 'en' ? style.containerEn : ''} ${
                is1024 ? style.container1024 : ''
            }`}
            id="about"
        >
            <HomeTitle title={t('about-title')}></HomeTitle>
            <div className={`${style.content} ${i18n.language === 'en' ? style.contentEn : ''}`}>
                <div className={style.tip}>{t('about-desc')}</div>
                {/* {isEN && <div className={style.tip2}>{t('about-desc2')}</div>} */}
                {/* <Lottie options={defaultOptions} className={style.lottie}></Lottie> */}
                <img src={i18n.language === 'zh' ? map : mapEn} alt="" className={style.mapimg} />
                <div className={style.bottom}>
                    <div className={style.left}>
                        {isEN && <img className={style.logo} src={logo1} alt="" />}
                        <div className={style.top}>{t('about-slogan')}</div>
                        <div className={style.bottom}>{t('about-slogan-desc')}</div>
                    </div>
                    <div className={style.right}>
                        {isEN && <img className={style.logo} src={logo2} alt="" />}
                        <div className={style.top}>{t('about-location')}</div>
                        <div className={style.bottom}>{t('about-location-desc')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default About;
