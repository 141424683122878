import React, { useState, useRef, useEffect, useMemo } from 'react';
import styles from './index.module.scss';
import data from './data.json';
import pathdata from './path.json';
import LogoHeader from '../../images/en_Logo.png';
import arrowUp from '../../images/chevron-up.png';
import arrowDown from '../../images/chevron-down.png';
import hongkong from '../../images/ic-office-hongkong.png';
import shenzhen from '../../images/ic-office-shenzhen.png';
import sg from '../../images/ic-office-singapore.png';
import beijing from '../../images/ic-office-beijing.png';
import wechatIcon from '../../images/ look-line-chat-1.png';
import whatsappicon from '../../images/whatsapp.png';
import mailIcon from '../../images/mail.png';
import linkedinIcon from '../../images/linkedin.png';
import personIcon from '../../images/user-plus.png';
import default_img from '../../images/avatar_default.png';
import { useTranslation } from 'react-i18next';
import misc from '@/services/misc';
// "15cfba78fc": "cathylu",
const BusinessCard: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const query = new URLSearchParams(window.location.search);
    const pathName = query.get('name') ?? '';
    const [name, setName] = useState('');
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (pathdata[pathName]) {
            setName(pathdata[pathName]);
            misc.eventReport([
                {
                    event_type: 2,
                    event_id: '70001',
                    params: { name: pathdata[pathName] },
                    ts: +new Date(),
                    sid: '',
                },
            ]);
        }
    }, [pathName]);
    const [showEn, setShowEn] = useState(false);
    useEffect(() => {
        if (data[name]) {
            if (data[name]['desc-en'] && data[name]['desc-en'].length > 0) {
                setShowEn(true);
            } else {
                setShowEn(false);
                i18n.changeLanguage('zh');
            }
        }
    }, [i18n, name]);
    const headerImg = useMemo(() => {
        if (name === '') {
            return '';
        }
        return 'https://media.ilookcap.com/statics/business_card/' + name + '_head.png';
    }, [name]);
    const handleContact = () => {
        misc.eventReport([
            {
                event_type: 2,
                event_id: '70002',
                params: { name: name, button: 'wechat' },
                ts: +new Date(),
                sid: '',
            },
        ]);

        const query = encodeURIComponent('id=' + name);
        // weixin://dl/business/?appid=*APPID*&path=*PATH*&query=*QUERY
        // window.location.href = `weixin://dl/business/?appid=wx905e58d6b8830dff&path=pages/theme/Page/detail/detail&query=${query}&env_version=release`;
        //pages/activity-none-menu/activity
        const path = `weixin://dl/business/?appid=wx905e58d6b8830dff&path=pages/business-card/index&query=${query}&env_version=release`;
        window.location.href = path;
    };
    const handleLinkin = () => {
        debugger;
        misc.eventReport([
            {
                event_type: 2,
                event_id: '70002',
                params: { name: name, button: 'linkedin' },
                ts: +new Date(),
                sid: '',
            },
        ]);
        // 处理LinkedIn链接的点击事件
        const linkedInUrl = data[name]?.vcard.urls.find(item => item.type === 'linkedin')?.url; // 替换为实际的LinkedIn个人资料URL
        if (linkedInUrl) {
            window.location.href = linkedInUrl;
        }
    };
    const showLinkedIn = () => {
        return data[name]?.vcard.urls.find(item => item.type === 'linkedin')?.url;
    };
    const showSendEmail = () => {
        return data[name]?.vcard.email;
    };
    const showWechat = () => {
        const noShowNames = ['dingpeng', 'janexu'];
        return noShowNames.findIndex(item => item === name) === -1;
    };
    const showWhatsapp = () => {
        return data[name]?.vcard.urls.find(item => item.type === 'whatsapp')?.url;
    };
    const handleMail = () => {
        misc.eventReport([
            {
                event_type: 2,
                event_id: '70002',
                params: { name: name, button: 'email' },
                ts: +new Date(),
                sid: '',
            },
        ]);
        window.location.href = `mailto:${data[name]?.vcard.email}?subject=&body=`;
    };
    const handleWhatsapp = () => {
        misc.eventReport([
            {
                event_type: 2,
                event_id: '70002',
                params: { name: name, button: 'whatsapp' },
                ts: +new Date(),
                sid: '',
            },
        ]);
        const whatsUrl = data[name]?.vcard.urls.find(item => item.type === 'whatsapp')?.url; // 替换为实际的LinkedIn个人资料URL
        if (whatsUrl) {
            window.location.href = whatsUrl;
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target instanceof Node ? event.target : null)
            ) {
                setShowModal(false);
            }
        };

        if (showModal) {
            window.addEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [showModal]);
    const downloadVCF = () => {
        misc.eventReport([
            {
                event_type: 2,
                event_id: '70002',
                params: { name: name, button: 'download' },
                ts: +new Date(),
                sid: '',
            },
        ]);
        const daisy = data[name];
        const photoUrl = 'https://media.ilookcap.com/statics/business_card/' + name + '_head.png';
        const vcfContent = `BEGIN:VCARD
VERSION:3.0
N:${daisy.vcard.name.familyName};${daisy.vcard.name.givenName};;;
FN:${daisy.vcard.fullName}
EMAIL;TYPE=INTERNET:${daisy.vcard.email}
ORG:${daisy.vcard.organization}
TITLE:${daisy.vcard.title}
ADR;TYPE=WORK:;;${daisy.vcard.address.streetAddress};${daisy.vcard.address.locality};;${
            daisy.vcard.address.postalCode
        };${daisy.vcard.address.country}
PHOTO;VALUE=URI:${photoUrl}
${daisy.vcard.urls.map((url, index) => `URL;type=${url.type}:${url.url}`).join('\n')}
END:VCARD`;
        const blob = new Blob([vcfContent], { type: 'text/vcard' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${daisy.vcard.fullName}.vcf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('下载成功');
    };
    const changeLanguage = (language: 'en' | 'zh') => {
        i18n.changeLanguage(language);
        window.localStorage.setItem('lk_lang', language);
    };
    const videoRef = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current
                .play()
                .then(() => {})
                .catch(err => {
                    debugger;
                    // Video couldn't play, low power play button showing.
                });
        }
    }, [videoRef]);
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (
        <div className={styles.container} style={{ backgroundColor: !isIos ? '#fafafa' : 'white' }}>
            {isIos && (
                <video
                    className={styles['background-video']}
                    muted
                    ref={videoRef}
                    aria-hidden="true"
                    src="https://media.ilookcap.com/statics/offcial_website/offical.mp4"
                    loop
                    playsInline
                />
            )}
            <header className={styles.header}>
                <img
                    src={LogoHeader}
                    onClick={() => {
                        window.location.href = window.location.origin;
                    }}
                    className={styles.logo}
                ></img>
                {showEn && (
                    <div className={styles['en-zh']}>
                        <button
                            onClick={() => {
                                changeLanguage('en');
                            }}
                            className={i18n.language === 'en' ? styles.active : ''}
                        >
                            EN
                        </button>
                        <button
                            onClick={() => {
                                changeLanguage('zh');
                            }}
                            className={i18n.language === 'zh' ? styles.active : ''}
                        >
                            中
                        </button>
                    </div>
                )}
            </header>
            <div className={styles.profile}>
                <div className={styles.top}>
                    <div className={styles['top-header']}>
                        <img
                            src={headerImg}
                            alt={name}
                            className={styles.profileImg}
                            onError={e => {
                                //设置src
                                e.currentTarget.src = default_img;
                            }}
                        />
                        <div className={styles.desc}>
                            <div className={styles.name}>
                                {i18n.language === 'en'
                                    ? data[name]?.vcard.name.givenName
                                    : data[name]?.vcard.fullName}
                            </div>
                            {data[name]?.titles.map((title, index) => (
                                <div key={index} className={styles.titles}>
                                    <span>{title.en}</span>
                                    {i18n.language === 'zh' && <span>{title.zh}</span>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {data[name]?.desc && data[name]?.desc.length > 0 && (
                    <ul className={styles.profileInfo}>
                        {i18n.language === 'zh' &&
                            data[name] &&
                            data[name]['desc'] &&
                            data[name]['desc'].map((item, index) => <li key={index}>{item}</li>)}
                        {i18n.language === 'en' &&
                            data[name] &&
                            data[name]['desc-en'] &&
                            data[name]['desc-en'].map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                )}
            </div>
            {/* <div className={styles.investment}>
                <div className={styles.investmentItems}>
                    <div className={styles.item}>
                        <img src="path/to/image1.jpg" alt="Investment 1" />
                        <p>2024 Q2 财报分享要点-苹果、微软、谷歌、Meta、亚马逊</p>
                    </div>
                    <div className={styles.item}>
                        <img src="path/to/image2.jpg" alt="Investment 2" />
                        <p>财富自由之后如何生活</p>
                    </div>
                </div>
            </div> */}
            <div className={styles.locations}>
                <div className={styles.locationItem}>
                    <img src={sg}></img>
                    <span>{t('address-sg')}</span>
                </div>
                <div className={styles.locationItem}>
                    <img src={hongkong}></img>
                    <span>{t('address-hongkong')}</span>
                </div>
                <div className={styles.locationItem}>
                    <img src={shenzhen}></img>
                    <span>{t('address-shenzhen')}</span>
                </div>
                <div className={styles.locationItem}>
                    <img src={beijing}></img>
                    <span>{t('address-china')}</span>
                </div>
            </div>
            <div className={styles.slogan}>
                <div className={styles.line}></div>
                <div className={styles.text}>{t('slogan')}</div>
                <div className={styles.line}></div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.stickyButton}>
                    <button className={styles.contactButton} onClick={() => setShowModal(true)}>
                        {t('contact-me')}
                        <img src={showModal ? arrowUp : arrowDown}></img>
                    </button>
                </div>

                {showModal && (
                    <div className={styles.modal} ref={modalRef}>
                        <div className={styles.modalContent}>
                            {showWechat() && (
                                <button onClick={handleContact}>
                                    <img src={wechatIcon}></img>
                                    {t('Add WeChat')}
                                </button>
                            )}
                            {showWhatsapp() && (
                                <button onClick={handleWhatsapp}>
                                    <img src={whatsappicon}></img>
                                    {t('Connect on Whatsapp')}
                                </button>
                            )}
                            {showSendEmail() && (
                                <button onClick={handleMail}>
                                    <img src={mailIcon}></img>
                                    {t('Send email')}
                                </button>
                            )}
                            {showLinkedIn() && (
                                <button onClick={handleLinkin}>
                                    <img src={linkedinIcon}></img>
                                    {t('Connect on Linkedin')}
                                </button>
                            )}
                            <button onClick={downloadVCF}>
                                <img src={personIcon}></img>
                                {t('Add to contacts')}
                            </button>
                        </div>
                    </div>
                )}
                <footer className={styles.footer}>
                    <span>{'2025 LOOK CAPITAL © Copyright'.toUpperCase()}</span>
                </footer>
            </div>
        </div>
    );
};

export default BusinessCard;
