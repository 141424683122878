import React, { useCallback, useEffect, useState } from 'react';
import './downloadMobile.scss';
import { LaunchApp, inWeixin } from 'web-launch-app';
import CheckVersion from '../../services/download';
import Arrow from './images/ic_weixin_download_arrow.svg';
import slogan from './images/download-mobile-slogan.png';
import Browser from './images/ic_download_browser.svg';
import { useTranslation } from 'react-i18next';
import logoen from './images/mobile-logo-en.png';

export default function DownloadMobile() {
    const lanchApp = new LaunchApp();
    const [updateTime, setUpdateTime] = useState('');
    const [updateVersion, setUpdateVersion] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        CheckVersion.checkVersion().then(res => {
            setUpdateTime(res.data.data.release_date);
            setUpdateVersion(res.data.data.new_version);
        });
    }, []);
    useEffect(() => {
        const u = navigator.userAgent;
        var ua = navigator.userAgent.toLowerCase();
        var isWeixin = ua.indexOf('micromessenger') != -1;
        if (isWeixin) {
            let url = window.location.href;
            if (url.indexOf('wechat') === -1) {
                url = url + '?wechat=1';
                window.location.replace(url);
                return;
            }
        }
        // if (!(u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) && !isWeixin) {
        handleDownload();
        // }
    }, []);

    const handleDownload = useCallback(() => {
        let lk = 'https://ilookcap.com/f-o-r-inner-a-p-k/lkcf.apk';
        lanchApp.download({
            android: lk,
            ios: 'https://apps.apple.com/cn/app/%E9%B9%BF%E5%8F%AF/id1574827009',
            launchType: {
                android: 'link',
                ios: 'store',
            },
            landPage: 'https://ilookcap.com/f-o-r-inner-a-p-k/lkcf.apk',
        });
    });

    return (
        <div className={`download-page ${i18n.language === 'en' ? 'download-page-en' : ''}`}>
            {inWeixin && (
                <div className="download-hint">
                    <img className="arrow" src={Arrow} alt="Download Arrow" />
                    <div className="text-container">
                        <img className="browser" src={Browser} alt="Browser" />
                        <span className="text">点击右上角选择浏览器打开</span>
                    </div>
                </div>
            )}
            <div className="download-container-mobile">
                {i18n.language !== 'en' && (
                    <>
                        <img src={slogan} alt="" className="download-container-mobile-slogan" />
                        <div className="downloadsubtitle">{t('banner-sub')}</div>
                    </>
                )}
                {i18n.language === 'en' && <img src={logoen} alt="" className="logo-en" />}
            </div>
            <div className="downloadbutton" onClick={handleDownload}>
                {t('download')}
            </div>
            <div className="downloadbottom">
                <div>{`${t('version')}：${updateVersion}`}</div>
                <div>{`${t('update-time')}：${updateTime}`}</div>
                <div>{`${t('developer')}：LOOK CAPITAL PTE. LTD`}</div>
            </div>
        </div>
    );
}
