import axios from 'axios';
import { setting } from '../config';

class CheckVersion {
    checkVersion = data => {
        const instance = axios.create({
            // timeout: 50000,
            baseURL: setting.lookcapHost,
        });
        return instance.post('/c/v1/version/check_updates', {
            req_id: `lk-${Date.parse(Date())}`,
            data: {},
            login: {},
            local: { locale: 'zh-CN', client_type: '4', app_ver: '1.2.10' },
        });
    };
}

export default new CheckVersion();
